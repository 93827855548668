































































import { defineComponent } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'

export default defineComponent({
  name: 'ConfirmModal',
  components: {
    SelectTimeSlot: () => import('@/components/molecules/select/timeSlot.vue'),
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    itemsTimeSlots: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      timeSlot: null,
      timeSlotStart: '',
      timeSlotEnd: '',
    }
  },
  methods: {
    clear () : void {
      this.timeSlot = null
      const observer = this.$refs.observer as InstanceType<typeof ValidationObserver>
      observer.reset()
    },
    cancel () : void {
      this.clear()
      this.$emit('close')
    },
    onSubmit () : void {
      this.$emit('changeHours', {
        startAt: this.timeSlotStart,
        endAt: this.timeSlotEnd,
      })
      this.clear()
      this.$emit('close')
    },
    changeHours (startAt: string, endAt: string) : void {
      this.timeSlotStart = startAt
      this.timeSlotEnd = endAt
    },
  },
})
